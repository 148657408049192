import type { ScanFilters } from '@projects/pages/detail/hooks';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { useEffect } from 'react';
import type { Photo } from '@photos/types';
import { HtmlWithTheme } from '@core/components';
import { zIndexRange3dHtml } from '@core/constants';
import { Badge, IconButton } from '@mui/material';
import { CameraFilled } from '@deepup/icons';
import { vecFromLatLong } from '@core/logic';
import { openCurrentPhotoInNewTab, setPreviewPhotos, togglePhotos, togglePreview } from './state';
import { getClusterPosition, gnssColor } from './helper';
import { useConnect } from './useConnect';

export const PhotoIcon = ({ photos, z }: { photos: Photo[]; z: number }) => {
  const isSinglePhoto = photos.length === 1;
  const position = isSinglePhoto
    ? vecFromLatLong(photos[0].lat, photos[0].long, z)
    : getClusterPosition(photos, z);

  return (
    <HtmlWithTheme
      position={position}
      center
      name={`${isSinglePhoto ? Object3dNames.Photo : Object3dNames.PhotoCluster}:${photos[0].id}`}
      zIndexRange={zIndexRange3dHtml}
    >
      <Badge
        overlap="circular"
        badgeContent={photos.length}
        color="secondary"
        sx={{
          filter: 'drop-shadow(0 0 3px #000000)',
        }}
        invisible={isSinglePhoto}
      >
        <IconButton
          sx={{
            'div:has(> div > .MuiBadge-root > &:hover)': {
              /*
               Brings the icon above all others on hover.
                TODO: This is highly specific to the react-three/drei + MUI
                  element nesting and might not work in future drei/MUI versions!
              */
              zIndex: zIndexRange3dHtml[0] + 1 + ' !important',
            },
          }}
          size="small"
          color={isSinglePhoto ? gnssColor[photos[0].geoPositionSolution] ?? 'white' : undefined}
          title="click to open photo in new tab"
          onMouseEnter={async () => {
            await setPreviewPhotos(photos);
            togglePreview(true);
          }}
          onMouseLeave={() => {
            togglePreview(false);
          }}
          onClick={openCurrentPhotoInNewTab}
        >
          <CameraFilled fontSize={isSinglePhoto ? 24 : 32} />
        </IconButton>
      </Badge>
    </HtmlWithTheme>
  );
};

export const PhotoIcons = (params: Pick<ScanFilters, 'dateRange' | 'viewport'>) => {
  const {
    showPhotos,
    photosInViewport: { singles, clusters },
    cameraViewport,
    projectId,
    fetchAllPhotosForProject,
  } = useConnect(params);

  useEffect(() => {
    fetchAllPhotosForProject();
    togglePhotos(false);
  }, [projectId]);

  if (!showPhotos || !cameraViewport) return null;

  return (
    <group name={Object3dNames.Photos} renderOrder={getRenderOrder(Object3dNames.Photos)}>
      {clusters.map((cluster) => (
        <PhotoIcon key={cluster.map((p) => p.id).join()} photos={cluster} z={cameraViewport.z} />
      ))}
      {singles.map((photo) => (
        <PhotoIcon key={photo.id} photos={[photo]} z={cameraViewport.z} />
      ))}
    </group>
  );
};
