import { isDateInRange } from '@core/logic';
import {
  type DateFilterType,
  DateRange,
  defaultDateRange,
  useScanFilters,
} from '@projects/pages/detail/hooks';
import { scanWithinBounds } from '@scans/helper';
import { scanSelectors } from '@scans/redux';
import type { QAState, Scan } from '@scans/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { proxy, useSnapshot } from 'valtio';
import { useTheme } from '@mui/material/styles';

export const useScansInScope = (): Scan[] => {
  const selectedProjectId = useSelector(viewer3dSelectors.selectedProjectId);

  const { dateRange, viewport, qaState, surfaceClassifications } = useScanFilters();

  const scans = useSelector(
    scanSelectors.selectWithFilter(
      (s) =>
        s.projectId === selectedProjectId &&
        (!qaState || qaState === s.qaState) &&
        (!surfaceClassifications || surfaceClassifications.includes(s.surfaceClassification)),
    ),
  );

  if (viewport) {
    return scans.filter(scanWithinBounds(viewport));
  }

  if (dateRange?.from && dateRange?.to) {
    return scans.filter((s) =>
      isDateInRange(
        dateRange.from,
        DateTime.fromISO(dateRange.to).plus({ days: 1 }).toISODate()!,
        dateRange.type === 'recordedAt' ? s.metadata?.beginDate || s.createdAt : s.uploadedAt || '',
      ),
    );
  }

  return scans;
};

interface ScanQaColors {
  qaColumnColors: { [key in QAState]: string };
  qaLabelColors: { [key in QAState]: { backgroundColor: string; color: string } };
}

export const useScanQaColors = (): ScanQaColors => {
  const theme = useTheme();

  const qaColumnColors = {
    TO_CLARIFY: theme.deepupColors.red[700],
    QA_READY: theme.deepupColors.gold[400],
    TO_LABEL: theme.deepupColors.red[500],
    DONE: theme.deepupColors.green[500],
    TO_REWORK: theme.deepupColors.blue[500],
  };

  const qaLabelColors = {
    TO_CLARIFY: {
      backgroundColor: theme.deepupColors.red[700],
      color: theme.palette.primary.contrastText,
    },
    QA_READY: {
      backgroundColor: theme.deepupColors.gold[400],
      color: theme.palette.primary.contrastText,
    },
    TO_LABEL: {
      backgroundColor: theme.deepupColors.red[400],
      color: theme.palette.primary.contrastText,
    },
    DONE: {
      backgroundColor: theme.deepupColors.green[400],
      color: theme.palette.primary.contrastText,
    },
    TO_REWORK: {
      backgroundColor: theme.deepupColors.blue[400],
      color: theme.palette.primary.contrastText,
    },
  };
  return {
    qaColumnColors,
    qaLabelColors,
  };
};

export const uiDateRangeState = proxy<{
  from: string;
  to: string;
  type: DateFilterType;
}>({} as DateRange);

export const setUiDateRangeState = ({ from, to, type }: DateRange) => {
  uiDateRangeState.from = from;
  uiDateRangeState.to = to;
  uiDateRangeState.type = type;
};

export const useDateRangeFilterState = () => {
  const { setDateRange, dateRange } = useScanFilters();

  // We store the ui state in valtio to persist data range when user switch between scans and elements tabs
  const uiState = useSnapshot(uiDateRangeState);

  const syncDateRange = ({
    from = uiState.from,
    to = uiState.to,
    type = uiState.type,
  }: Partial<DateRange>) => {
    setDateRange({ from, to, type });
    setUiDateRangeState({ from, to, type });
  };

  const isDateModeActive = !!dateRange;

  // Set date range on component mount
  useEffect(() => {
    setUiDateRangeState({
      from: dateRange?.from ?? uiState.from ?? defaultDateRange.from,
      to: dateRange?.to ?? uiState.to ?? defaultDateRange.to,
      type: dateRange?.type ?? uiState.type ?? defaultDateRange.type,
    });
  }, []);

  return {
    isDateModeActive,
    syncDateRange,
    dateRangeFilter: uiState,
  };
};
