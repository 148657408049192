import type { Entity } from '@core/types';

export interface ScanBoundingBox {
  latitudeMax: number;
  latitudeMin: number;
  longitudeMax: number;
  longitudeMin: number;
}

export interface ScanMetadata {
  beginDate?: string;
  boundingBox?: ScanBoundingBox;
  endDate?: string;
}

export interface ScanLocation {
  city?: string;
  latitudeMean: number;
  longitudeMean: number;
  place?: string;
}

export const reworkCategories = [
  'TRENCH_OR_ELEMENT_PLACEMENT',
  'ADDRESS',
  'CONNECTOR_OR_PIPE_FITTING',
  'SCAN_POSITION',
  'PIPE_COLOUR',
  'PIPE_STRENGTH',
  'MISSING_ELEMENT',
  'ELEMENT_NAME',
  'DOUBLE_CHECK_PHOTOS',
  'SURFACE_TYPE',
  'LAYING_TYPE',
  'WIDTH',
  'DEPTH',
] as const;
export type ReworkCategory = typeof reworkCategories[number];

export const qaStates = ['QA_READY', 'DONE', 'TO_LABEL', 'TO_REWORK', 'TO_CLARIFY'] as const;
export type QAState = typeof qaStates[number];

export type QaStateUpdate = {
  qaState: QAState;
  reworkCategories?: ReworkCategory[];
  id: string; // this is the scanId
};

export const surfaceClassificationCategories = ['NOT_CLASSIFIED', 'SURFACE', 'TRENCH'] as const;
export type SurfaceClassification = typeof surfaceClassificationCategories[number];

export const surfaceClassificationFeedbackReasons = {
  WRONG_CLASSIFICATION: 'Wrong class assignment',
  SOIL_DISPLACEMENT: 'Soil displacement',
  FLUSH_DRILLING: 'Flush drilling',
  OTHER: 'Other',
} as const;

export type SurfaceClassificationFeedbackReasons =
  keyof typeof surfaceClassificationFeedbackReasons;

export type SurfaceClassificationFeedback = {
  newClassification: Exclude<typeof surfaceClassificationCategories[number], 'NOT_CLASSIFIED'>;
  reason?: SurfaceClassificationFeedbackReasons;
};

export interface Scan extends Entity {
  detailsFetched?: boolean;
  disableUserTransformation?: boolean;
  externalScanId?: string;
  loaded?: boolean; // TODO since this prop doesn't directly belong to the data model but is a view prop of the scene consider putting loaded scanIds in an array prop of viewer3d slice.
  location?: ScanLocation;
  metadata: ScanMetadata;
  projectId?: string | null;
  selectedProcessingResultId?: string;
  tagIds: string[];
  uploadedAt?: string;
  visible: boolean;
  qaState: QAState;
  surfaceClassification: SurfaceClassification;
  reworkCategories: ReworkCategory[];
  deviceName?: string;
}
