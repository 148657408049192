import { editModeState } from '@projects/edit-modes';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { FC, useEffect, useState } from 'react';
import { Vector3 } from 'three';
import { useSnapshot } from 'valtio';
import { connectComponent } from './connect';
import { DeepupCatmullRomCurve } from './DeepupCatmullRomCurve';
import { getTrenchConfiguration } from './helper';
import { ElementPoints, IntermediatePoints, TrenchPoints } from './Points';
import { AttributePoints } from './Points/AttributePoints';
import { TrenchSegments } from './Segments';
import type { MergedProps } from './types';

const WORLD_ORIGIN = new Vector3();

const TrenchBase: FC<MergedProps> = ({
  trenchId,
  pointTrenchMap,
  points,
  segments,
  isSelected,
  selectTrench,
}) => {
  const [curve, setCurve] = useState<DeepupCatmullRomCurve>(new DeepupCatmullRomCurve());
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { mode } = useSnapshot(editModeState, { sync: true });

  useEffect(() => {
    if (!points.length) return;
    curve.updatePoints(points);
    if (curve.needsUpdate) setCurve(curve.clone());
  }, [curve, points]);

  const isCurveAtWorldOrigin = curve.worldPosition.equals(WORLD_ORIGIN);
  if (isCurveAtWorldOrigin) return null;

  const { events, render } = getTrenchConfiguration(mode, curve);
  const onOverHandler = (over: boolean) => () => setIsMouseOver(over);
  return (
    <group
      name={`${Object3dNames.Trench}:${trenchId}`}
      position={curve.worldPosition}
      renderOrder={getRenderOrder(Object3dNames.Trench)}
      onPointerOver={events.group ? onOverHandler(true) : undefined}
      onPointerOut={events.group ? onOverHandler(false) : undefined}
      onClick={events.group ? selectTrench : undefined}
    >
      {render.segments && (
        <TrenchSegments
          trenchId={trenchId}
          isMouseOverTrench={isMouseOver}
          isTrenchSelected={isSelected}
          curve={curve}
          segments={segments}
          mode={mode}
        />
      )}
      {render.trenchPoints && (
        <TrenchPoints trenchId={trenchId} curve={curve} pointTrenchMap={pointTrenchMap} />
      )}
      {render.elementPoints && (
        <ElementPoints curve={curve} trenchId={trenchId} pointTrenchMap={pointTrenchMap} />
      )}
      {render.attributePoints && (
        <AttributePoints trenchId={trenchId} curve={curve} pointTrenchMap={pointTrenchMap} />
      )}
      {render.intermediatePoints && <IntermediatePoints trenchId={trenchId} curve={curve} />}
    </group>
  );
};

export const Trench = connectComponent(TrenchBase);
