import { useKey } from '@core/logic';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState } from 'react';
import { errorBox } from '@core/components/AlertBox';
import { ConnectionsView } from './ConnectionView';
import { PipeView } from './PipeView';
import type { Pipe } from './types';
import { useCreateFitting, usePipeFittingsRendering } from './usePipeFittings';

export const PipeFittings = () => {
  const { incomingPipes, connections, outgoingPipes } = usePipeFittingsRendering();
  const createFitting = useCreateFitting();

  const containerRef = useRef<HTMLDivElement>(null);
  const [expandedIncomingBundle, setExpandedIncomingBundle] = useState<Pipe | undefined>(
    incomingPipes.find((p) => p.children?.length),
  );

  const togglePipe = (pipe: Pipe) => {
    setExpandedIncomingBundle((prevPipe) => (prevPipe?.id === pipe.id ? undefined : pipe));
  };

  const [nextConnection, setNextConnection] = useState<{
    in?: { parentId: Pipe['id']; childId?: Pipe['id'] };
    out?: { parentId: Pipe['id']; childId?: Pipe['id'] };
  }>({});

  const handleDoubleClickOutgoingPipe = () => {
    if (!expandedIncomingBundle) {
      errorBox('No incoming bundle open. Please select a bundle first.');
      return;
    }

    const sameColorIncomingPipe = expandedIncomingBundle.children?.find(
      (pipe) => pipe.id === nextConnection.out?.childId,
    );
    if (sameColorIncomingPipe) {
      setNextConnection((s) => ({
        ...s,
        in: { parentId: expandedIncomingBundle.id, childId: sameColorIncomingPipe.id },
      }));
    } else {
      errorBox(
        'No matching speedpipe in incoming bundle. Does incoming have the selected color AND diameter as child?',
      );
    }
  };

  const onClickPipe = (parentId: Pipe['id'], childId?: Pipe['id']) => {
    const setConnection = (type: 'in' | 'out') =>
      setNextConnection((s) => ({ ...s, [type]: { parentId, childId } }));

    if (parentId === null) {
      // "Unknown"-fittings
      setConnection(!nextConnection.in ? 'in' : 'out');
    } else if (incomingPipes.some((p) => p.id === parentId)) {
      setConnection('in');
    } else if (!nextConnection.out && outgoingPipes.some((p) => p.id === parentId)) {
      setConnection('out');
    } else if (
      nextConnection.out &&
      parentId === nextConnection.out.parentId &&
      childId === nextConnection.out.childId
    ) {
      handleDoubleClickOutgoingPipe();
    }
  };

  useEffect(() => {
    if (!nextConnection.in || !nextConnection.out) return;

    createFitting({
      incomingSplineElementId: nextConnection.in.parentId,
      incomingColor: nextConnection.in.childId,
      outgoingSplineElementId: nextConnection.out.parentId,
      outgoingColor: nextConnection.out.childId,
    });
    // highlight both pipes for 1 second
    setTimeout(() => setNextConnection({}), 1000);
  }, [nextConnection.in && nextConnection.out]);

  const escPressed = useKey('Escape');

  useEffect(() => {
    escPressed && setNextConnection({});
  }, [escPressed]);

  const highlightedPipeIds = [
    nextConnection.in?.childId ?? nextConnection.in?.parentId,
    nextConnection.out?.childId ?? nextConnection.out?.parentId,
  ];
  return (
    <Grid container padding={2} ref={containerRef}>
      <Grid item container xs={5.2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography noWrap align="left" fontWeight={700}>
            Incoming Pipes
          </Typography>
        </Grid>
        {incomingPipes.map((pipe, i) => (
          <PipeView
            key={i}
            pipe={pipe}
            isIncoming
            isExpanded={pipe.id === expandedIncomingBundle?.id}
            onClick={onClickPipe}
            onToggle={togglePipe}
            highlightedPipeId={highlightedPipeIds[0]}
          />
        ))}
      </Grid>
      <ConnectionsView
        containerRef={containerRef}
        connections={connections}
        highlightedConnection={connections.findIndex(
          (connection) =>
            highlightedPipeIds[0] === connection.inPipe.id &&
            highlightedPipeIds[1] === connection.outPipe.id,
        )}
      />
      <Grid item container xs={5.2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography noWrap align="left" fontWeight={700}>
            Outgoing Pipes
          </Typography>
        </Grid>
        {outgoingPipes.map((pipe, i) => (
          <PipeView
            key={i}
            pipe={pipe}
            onClick={onClickPipe}
            highlightedPipeId={highlightedPipeIds[1]}
          />
        ))}
      </Grid>
    </Grid>
  );
};
