import type { ThreeEvent } from '@react-three/fiber';
import { PointColors, PointOpacity } from '@trenches/colors';
import { enableLayerOne, getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { sphereGeometry } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { FC, useState } from 'react';
import type { Vector3 } from 'three';

interface TrenchPointProps {
  addKeyPressed: boolean;
  isEndPoint: boolean;
  isPointSelected: boolean;
  isTrenchSelected: boolean;
  numberOfTrenches: number;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
  pointId: string;
  position: Vector3;
  removeKeyPressed: boolean;
  size: number;
}

const getPointColor = (
  isTrenchSelected: boolean,
  containsTrenchEnd: boolean,
  addKeyPressed: boolean,
  removeKeyPressed: boolean,
  mouseOver: boolean,
  isPointSelected: boolean,
): string => {
  if (!isTrenchSelected && mouseOver && addKeyPressed) return PointColors.Selected;
  if (mouseOver && removeKeyPressed) return PointColors.Delete;
  if (isPointSelected) return PointColors.Selected;
  if (mouseOver) return PointColors.Hover;
  if (containsTrenchEnd) return PointColors.Endpoint;
  return PointColors.Default;
};

const getOpacity = (
  isMouseOver: boolean,
  isPointSelected: boolean,
  isTrenchSelected: boolean,
  numberOfTrenches: number,
): number => {
  const opacity =
    isMouseOver || isPointSelected || isTrenchSelected ? PointOpacity.Hover : PointOpacity.Default;
  return opacity / numberOfTrenches;
};

export const TrenchPoint: FC<TrenchPointProps> = ({
  pointId,
  isPointSelected,
  isTrenchSelected,
  isEndPoint,
  numberOfTrenches,
  addKeyPressed,
  removeKeyPressed,
  size,
  position,
  onClick,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const prohibitClickAction = !isTrenchSelected && removeKeyPressed; // extend boolean expression if desired!
  // point is neither selected nor is this action allowed on it -> hide it!
  if (prohibitClickAction && !isPointSelected) {
    return null;
  }

  const mouseOverHandler = (e: ThreeEvent<MouseEvent>) => {
    if (removeKeyPressed) e.stopPropagation();
    setIsMouseOver(true);
  };

  const pointColor = getPointColor(
    isTrenchSelected,
    isEndPoint || numberOfTrenches > 1,
    addKeyPressed,
    removeKeyPressed,
    isMouseOver,
    isPointSelected,
  );
  const opacity = getOpacity(isMouseOver, isPointSelected, isTrenchSelected, numberOfTrenches);
  const material = getCachedMaterial(pointColor, opacity);
  return (
    <mesh
      name={`${Object3dNames.TrenchPoint}:${pointId}`}
      scale={[size, size, size]}
      matrixWorldNeedsUpdate={true}
      position={position}
      geometry={sphereGeometry}
      material={material}
      onClick={prohibitClickAction ? undefined : onClick}
      onPointerOver={mouseOverHandler}
      onPointerOut={() => setIsMouseOver(false)}
      onUpdate={enableLayerOne}
      renderOrder={getRenderOrder(Object3dNames.TrenchPoint)}
    />
  );
};
