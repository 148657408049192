import type { Entity } from '@core/types';

export const ascQualitySortedGeoPositionSolutions = ['CORELOCATION', 'FLOAT', 'FIXED'] as const;
export type GeoPositionSolution = typeof ascQualitySortedGeoPositionSolutions[number];

export interface Photo extends Entity {
  address: string | null;
  comment: string | null;
  deviceId: string;
  geoPositionSolution: GeoPositionSolution;
  lat: number;
  long: number;
  projectId: string | null;
  recordedAt: string;
  url: string;
  category?: { id: string; name: string } | null;
}
