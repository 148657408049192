import { request } from '@core/api';
import { errorBox } from '@core/components/AlertBox';
import { equalLengthAndValues } from '@core/logic';
import type { TrenchPrelabel } from '@core/types';
import { scanSelectors } from '@scans/redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { proxy, useSnapshot } from 'valtio';

// PRELABELED TRENCHES
export const prelabeledTrenchesState = proxy<{
  show: boolean;
  trenches: TrenchPrelabel[];
  fetchedScanIds: string[];
}>({
  show: false,
  trenches: [],
  fetchedScanIds: [],
});

export const togglePrelabeledTrenchVisibility = (): void => {
  prelabeledTrenchesState.show = !prelabeledTrenchesState.show;
};

export const hidePrelabeledTrenches = (): void => {
  prelabeledTrenchesState.show = false;
};

export const setPrelabels = (trenches: TrenchPrelabel[]): void => {
  prelabeledTrenchesState.trenches = trenches;
};

const setFetchedScanIds = (scanIds: string[]) => {
  prelabeledTrenchesState.fetchedScanIds = scanIds;
};

export const usePrelabeleTrenchesdState = () => useSnapshot(prelabeledTrenchesState);

export const useFetchPrelabeledTrenches = async (scanIds: string[]): Promise<void> => {
  const scans = useSelector(scanSelectors.selectByIds(scanIds));
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const { fetchedScanIds } = usePrelabeleTrenchesdState();
  if (!scanIds.length || equalLengthAndValues(scanIds, fetchedScanIds)) {
    return;
  }
  setFetchedScanIds(scanIds);
  const requests = scans.map((scan) =>
    request<TrenchPrelabel[]>('GET', {
      path: [
        'projects',
        projectId!,
        'processingResults',
        scan.selectedProcessingResultId!,
        'prelabels',
        'trench',
      ],
    }),
  );
  try {
    const prelabels = await Promise.all<TrenchPrelabel[]>(requests);
    if (!prelabels.length) {
      errorBox('No prelabeled trenches found for visible scans');
    } else {
      setPrelabels(prelabels.flat());
    }
  } catch (error) {
    errorBox('Error fetching prelabeled trenches');
  }
};
