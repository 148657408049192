import { CustomScrollContainer } from '@core/components';
import { infoBox } from '@core/components/AlertBox';
import { dateTimeFormatter } from '@core/logic';
import { AppDispatch } from '@core/redux/store';
import { TrashcanOutline } from '@deepup/icons';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { ProcessingResultsList } from '@processingResults/components';
import { processingResultSelectors } from '@processingResults/redux';
import type { ProcessingResult } from '@processingResults/types';
import { scanActions, scanThunks } from '@scans/redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';
import { SurfaceDetails } from './SurfaceDetails';
import {
  setTransformTrenchPointsWithScan,
  setTriggerUndoUseUserTransformation,
  transformTrenchPointsWithScanState,
} from './state';

export const ScanDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const scan = useSelector(viewer3dSelectors.selectedScan);
  const processingResults = useSelector(
    processingResultSelectors.selectByProps({ scanId: scan?.id }),
  );
  const { applyTransformation: transformAllPointsCheckbox } = useSnapshot(
    transformTrenchPointsWithScanState,
  );

  const changeUseUserTransformationHandler = useCallback(() => {
    if (!scan?.id) return;
    dispatch(
      scanActions.updateOne({
        id: scan.id,
        disableUserTransformation: !scan.disableUserTransformation,
      }),
    );
  }, [scan?.id, scan?.disableUserTransformation, dispatch]);

  useEffect(() => {
    if (!scan?.id || scan.detailsFetched) return;
    dispatch(
      scanThunks.getOne({
        path: ['scans', scan.id],
        params: { projection: 'scanDetail' },
      }),
    )
      .then(() =>
        dispatch(
          scanActions.updateOne({
            id: scan.id,
            detailsFetched: true,
          }),
        ),
      )
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, scan?.id, scan?.detailsFetched]);

  if (!scan) return null;

  const selectResultHandler = (result: ProcessingResult) => {
    dispatch(
      scanThunks.patch({
        path: ['scans', scan.id],
        body: {
          id: scan.id,
          selectedProcessingResultId: result.id,
        },
      }),
    ).catch(console.error);
  };

  const { metadata, location, id, deviceName, surfaceClassification } = scan;

  const scanProps = [
    ['ID', id],
    deviceName ? ['Device Name', deviceName] : undefined,
    location?.place ? ['Location', location?.place] : undefined,
    ['Recorded At', metadata.beginDate ? dateTimeFormatter(metadata.beginDate) : 'unknown'],
    metadata.beginDate && metadata.endDate
      ? [
          'Duration',
          Math.round(
            (new Date(metadata.endDate).getTime() - new Date(metadata.beginDate).getTime()) / 1000,
          ),
        ]
      : undefined,
    ['Uploaded At', dateTimeFormatter(scan.uploadedAt)],
  ].filter(Boolean) as [string, string][];

  const copyToClipboard = (key: string, text: string) => {
    navigator.clipboard.writeText(text);
    infoBox(`Copied ${key} to clipboard`);
  };

  return (
    <CustomScrollContainer>
      <List dense>
        {scanProps.map(([primary, secondary]) => (
          <ListItemButton key={primary} onClick={() => copyToClipboard(primary, secondary)}>
            <ListItemText primary={primary} secondary={secondary} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <Stack gap={1} padding={2}>
        <Typography variant="overline">Scan Surface Type</Typography>
        <SurfaceDetails surfaceClassification={surfaceClassification} scanId={id} />
      </Stack>
      <Divider />
      <Stack gap={1} padding={2}>
        <Typography variant="overline">User Transformation</Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>Transform all points with scan</Typography>
          <Switch
            checked={transformAllPointsCheckbox}
            onClick={() => setTransformTrenchPointsWithScan(!transformAllPointsCheckbox)}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>
            {scan.disableUserTransformation ? 'Show Transformations' : 'Show Transformations'}
          </Typography>
          <Switch
            checked={!scan.disableUserTransformation}
            onClick={changeUseUserTransformationHandler}
          />
        </Stack>
        <Button
          variant="outlined"
          color="error"
          disabled={!scan.disableUserTransformation}
          onClick={() => setTriggerUndoUseUserTransformation(true)}
          endIcon={<TrashcanOutline />}
        >
          Reset all User Transformations
        </Button>
      </Stack>
      <Divider />
      <Stack gap={1} padding={2}>
        <Typography variant="overline">Processing Results</Typography>
        <ProcessingResultsList
          selectedProcessingResultId={scan.selectedProcessingResultId}
          processingResults={processingResults}
          onSelectResult={selectResultHandler}
        />
      </Stack>
      <Divider />
    </CustomScrollContainer>
  );
};
