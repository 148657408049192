import type { EditModeState } from '@projects/edit-modes/types';
import { EditMode } from '@projects/edit-modes/types';
import { proxy, useSnapshot } from 'valtio';

export const editModeState = proxy<EditModeState>({
  mode: EditMode.Scan,
});

export const setEditMode = (nextMode: EditMode): void => {
  editModeState.mode = nextMode;
};

export const useEditModeState = () => {
  const state = useSnapshot(editModeState);
  return {
    isAttributesMode: state.mode === EditMode.TrenchAttributes,
    ...state,
  };
};
