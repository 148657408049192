import type { Auth0ProviderOptions, AuthorizationParams } from '@auth0/auth0-react';

interface RequiredAuthorizationParams extends AuthorizationParams {
  audience: Required<AuthorizationParams['audience']>;
  scope: Required<AuthorizationParams['scope']>;
  redirect_uri: Required<AuthorizationParams['redirect_uri']>;
}

interface Auth0Environment extends Auth0ProviderOptions {
  authorizationParams: RequiredAuthorizationParams;
}

export interface Environment {
  apiUrl: string;
  auth0: Auth0Environment;
  dataCy: boolean;
  isDev: boolean;
  mapBox: { accessToken: string };
  scanDashboardUrl: string;
  useMockApi?: boolean;
  trenchviewerFrontendUrl: string;
}

// All custom variables defined in import.meta.env can be found in your local
// .env.[mode] file with mode = development | test | production
const dotEnv = import.meta.env || {};

export const environment: Environment = {
  auth0: {
    domain: dotEnv.VITE_AUTH0_DOMAIN,
    clientId: dotEnv.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: dotEnv.VITE_AUTH0_AUDIENCE,
      scope: dotEnv.VITE_AUTH0_SCOPE,
      redirect_uri: window.location.origin,
    },
  },
  mapBox: {
    accessToken: dotEnv.VITE_MAPBOX_ACCESS_TOKEN,
  },
  isDev: dotEnv.MODE === 'development',
  apiUrl: dotEnv.VITE_API_URL,
  scanDashboardUrl: dotEnv.VITE_GRAFANA_SCAN_DASHBOARD_URL,
  dataCy: !!dotEnv.VITE_DATACY,
  useMockApi: dotEnv.VITE_ENABLE_MOCK_API === 'true',
  trenchviewerFrontendUrl: dotEnv.VITE_TRENCHVIEWER_FRONTEND_URL,
};
