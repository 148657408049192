import { ChevronsDown } from '@deepup/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  IconButton,
  ListItemButton,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import type { RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPipeIcon, maintainGoodContrast, pipeIcons } from '@projects/pages/detail/helper';
import { pipeColors } from './pipeColors';
import type { Pipe } from './types';

export const PipeView = ({
  isIncoming = false,
  pipe,
  onClick,
  isExpanded,
  onToggle,
  highlightedPipeId,
}: {
  isIncoming?: boolean;
  pipe: Pipe;
  onClick: (pipeId: Pipe['id'], color?: Pipe['id']) => void;
  isExpanded?: boolean;
  onToggle?: (pipe: Pipe) => void;
  highlightedPipeId?: Pipe['id'];
}) => {
  const { id, name, color, ref, children } = pipe;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const PipeIcon = getPipeIcon(name, children?.length);

  return (
    <Grid item xs={12}>
      <Card
        variant="outlined"
        sx={{
          borderColor:
            highlightedPipeId === pipe.id
              ? theme.palette.primary.main
              : selectedElementId === id
              ? theme.palette.common.white
              : undefined,
          borderRadius: children ? undefined : theme.shape.borderRadius,
        }}
        onMouseEnter={() => id && dispatch(viewer3dActions.setSelectedElementIds([id]))}
      >
        <Accordion
          disableGutters
          expanded={isExpanded}
          onChange={() => onToggle?.(pipe)}
          elevation={8}
        >
          <AccordionSummary
            expandIcon={
              children && (
                <ChevronsDown fontSize="large" fill="white" data-testid="collapse-toggle" />
              )
            }
            sx={{
              direction: isIncoming ? 'rtl' : 'ltr',
              '.MuiAccordionSummary-content': {
                alignItems: 'center',
                margin: 0,
              },
              padding: `0 ${theme.spacing(1)}`,
            }}
          >
            <IconButton
              ref={ref as RefObject<HTMLButtonElement>}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onClick(pipe.id);
              }}
              data-testid={`pipe-${pipe.id}`}
            >
              <PipeIcon fill={color} filter={maintainGoodContrast(color)} fontSize={24} />
            </IconButton>
            <Typography variant="subtitle2" flexGrow={0} flexShrink={1}>
              {name}
            </Typography>
          </AccordionSummary>
          {children && (
            <>
              <Divider />
              <AccordionDetails
                sx={{
                  transition: theme.transitions.create(['transform']),
                  transform: `translateY(-${theme.spacing(2)})`,
                  '.Mui-expanded &': {
                    transform: 'translateY(0)',
                  },
                  padding: `${theme.spacing(1)} 0`,
                }}
              >
                {children.map((child, i) => {
                  const { name, color, ref } = child;
                  const SpeedPipeIcon =
                    i >= pipeColors.length / 2 ? pipeIcons.SingleDashed : pipeIcons.Single;
                  return (
                    <ListItemButton
                      sx={{
                        direction: isIncoming ? 'rtl' : 'ltr',
                        transition: theme.transitions.create([
                          'opacity',
                          'transform',
                          'background',
                        ]),
                        opacity: 0,
                        transform: `translateY(calc(-100% * ${i + 1}))`,
                        '.Mui-expanded &': {
                          opacity: 1,
                          transform: 'translateY(0)',
                        },
                        padding: `0 ${theme.spacing(1)}`,
                      }}
                      key={i}
                      selected={highlightedPipeId === child.id}
                    >
                      <IconButton
                        ref={ref as RefObject<HTMLButtonElement>}
                        size="small"
                        onClick={() => onClick(pipe.id, child.id)}
                        data-testid={`color-${child.id}`}
                      >
                        <SpeedPipeIcon
                          fill={color}
                          filter={maintainGoodContrast(color)}
                          fontSize={24}
                        />
                      </IconButton>
                      <Typography variant="body1">{name}</Typography>
                    </ListItemButton>
                  );
                })}
              </AccordionDetails>
            </>
          )}
        </Accordion>
      </Card>
    </Grid>
  );
};
