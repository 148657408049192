import { pointElementSelectors } from '@elements/redux/point-element.slice';
import type { PointElement, SplineElement } from '@elements/types';
import { useSelector } from 'react-redux';
import { Autocomplete, Grid, lighten, ListItem, TextField } from '@mui/material';
import { formatAddress } from '@core/logic';
import { DateTime } from 'luxon';

export interface ElementDialogProps<T = SplineElement | PointElement> {
  elementToUpdate?: T;
  onClose: () => void;
}

export const DIALOG_WIDTH = 600;

export const ParentSelectAutocomplete = ({
  elementToUpdate,
  updateProp,
}: {
  elementToUpdate: ElementDialogProps['elementToUpdate'];
  updateProp: (key: string, value: string) => void;
}) => {
  const allNetworkNodes = useSelector(
    pointElementSelectors.selectWithFilter(
      (networkNode) =>
        // only get network nodes and cut out the currently edited element if it is a point element
        networkNode.kind === 'NETWORK_NODE' && networkNode.id !== elementToUpdate?.id,
    ),
  );

  return (
    <Autocomplete
      defaultValue={allNetworkNodes.find((t) => t.id === elementToUpdate?.parentPointElementId)}
      getOptionLabel={(option) => option.name + ', ' + formatAddress(option.address)}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label="Parent Network Node" fullWidth />
      )}
      onChange={(e, value, reason) => {
        if (reason === 'selectOption')
          updateProp('parentPointElementId', (value as PointElement).id);
        else if (reason === 'clear') updateProp('parentPointElementId', '');
      }}
      options={allNetworkNodes}
      openOnFocus
      componentsProps={{ paper: { elevation: 24 } }}
      groupBy={() => ''} // this creates one group of all items
      renderGroup={({ key, children }) => (
        <Grid container key={key} fontSize={(theme) => theme.typography.body2.fontSize}>
          <ListItem
            sx={(theme) => ({
              position: 'sticky',
              top: theme.spacing(-1),
              zIndex: 1,
              backgroundColor: lighten(theme.palette.grey[100], 0.055), // TODO this is a workaround! Should be implemented in theme!
              fontWeight: 600,
              color: theme.palette.text.secondary,
            })}
            divider
          >
            <Grid item xs={3}>
              Name
            </Grid>
            <Grid item xs={7}>
              Address
            </Grid>
            <Grid item xs={2}>
              Date
            </Grid>
          </ListItem>
          {children}
        </Grid>
      )}
      renderOption={(props, option) => (
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={option.id}
          sx={{
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Grid item xs={3} sx={{ overflowWrap: 'break-word' }}>
            {option.name}
          </Grid>
          <Grid
            item
            xs={7}
            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {formatAddress(option.address)}
          </Grid>
          <Grid item xs={2}>
            {DateTime.fromISO(option.createdAt).toLocaleString()}
          </Grid>
        </ListItem>
      )}
    />
  );
};
