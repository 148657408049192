import { dateTimeFormatter, toHumanReadable, useKey } from '@core/logic';
import { Card, Chip, Fade, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import { useTheme } from '@mui/material/styles';
import { gnssColor } from './helper';
import { openCurrentPhotoInNewTab, photosState, showNextPhoto, togglePreview } from './state';

export const PhotoPreview = () => {
  const { showPreview, previewPhotos, currentPhotoIndex, isLoading } = useSnapshot(photosState);
  const photoRefs = useRef<HTMLDivElement[]>([]);
  const nextPhotoPressed = useKey(' ', true);
  const theme = useTheme();

  useEffect(() => {
    previewPhotos.length > 1 && nextPhotoPressed && showNextPhoto();
  }, [nextPhotoPressed]);

  useEffect(() => {
    photoRefs.current[currentPhotoIndex]?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentPhotoIndex]);

  const photoWidth = theme.spacing(37);
  const maxWidth = theme.spacing(45);

  return (
    <Fade in={showPreview}>
      <Card
        elevation={12}
        sx={{ position: 'fixed', right: 0, bottom: 0, mr: 2, mb: 2, zIndex: theme.zIndex.modal }}
        onMouseLeave={() => togglePreview(false)} // hides the preview overlay
      >
        <Stack
          direction="row"
          overflow="hidden"
          maxWidth={maxWidth}
          sx={{ scrollSnapType: 'x mandatory' }}
        >
          {!isLoading &&
            previewPhotos.map((p, i) => (
              <Stack
                key={p.id}
                sx={{
                  scrollSnapAlign: i < previewPhotos.length - 1 ? 'start' : 'end',
                  width: photoWidth,
                  transition: theme.transitions.create('opacity', { easing: 'ease-in-out' }),
                  opacity: i === currentPhotoIndex ? 1 : 0.3,
                  '& img': {
                    minHeight: 400,
                  },
                }}
                ref={(ref) => (photoRefs.current[i] = ref!)}
              >
                <img
                  src={p.url}
                  alt="preview"
                  width={photoWidth}
                  onClick={openCurrentPhotoInNewTab}
                />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>GNSS:</TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          color={gnssColor[p.geoPositionSolution] ?? 'secondary'}
                          label={toHumanReadable(p.geoPositionSolution)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date:</TableCell>
                      <TableCell>{dateTimeFormatter(p.recordedAt)}</TableCell>
                    </TableRow>
                    {p.address && (
                      <TableRow>
                        <TableCell>Address:</TableCell>
                        <TableCell>{toHumanReadable(p.address)}</TableCell>
                      </TableRow>
                    )}
                    {p.category && (
                      <TableRow>
                        <TableCell>Category:</TableCell>
                        <TableCell>{toHumanReadable(p.category.name)}</TableCell>
                      </TableRow>
                    )}
                    {p.comment && (
                      <TableRow>
                        <TableCell>Comment:</TableCell>
                        <TableCell>{p.comment}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Stack>
            ))}
        </Stack>
      </Card>
    </Fade>
  );
};
