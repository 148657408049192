import { Selectable3DObjectList } from '@projects/components';
import { useKey } from '@core/logic';
import { useConnect } from './connect';

export const ElementSelect = () => {
  const shiftPressed = useKey('Shift');
  const { mergeElements, splines, points, selectElement, splitElement } = useConnect(shiftPressed);
  return (
    <>
      <Selectable3DObjectList
        items={splines}
        selectItem={selectElement}
        onMergeItems={mergeElements}
        onSplitItem={splitElement}
      />
      <Selectable3DObjectList items={points} selectItem={selectElement} />
    </>
  );
};
