import { Divider, Stack, Tab, Tabs } from '@mui/material';
import { CustomScrollContainer } from '@core/components';
import { useSnapshot } from 'valtio';
import { useKey } from '@core/logic';
import { setSelectedTab, tabs } from './state';
import { PipeFittings } from './PipeFittings';
import { MarkerSphere } from './MarkerSphere/MarkerSphere';
import { PipeConnectors } from './PipeConnectors/PipeConnectors';
import { TrenchSelect } from './TrenchSelect';
import { ElementSelect } from './ElementSelect';
import { HouseLeadDetails } from './HouseLeadDetails';

export const TrenchPointDetails = () => {
  return (
    <CustomScrollContainer gap={2} flexGrow={1}>
      <TrenchSelect />
    </CustomScrollContainer>
  );
};

export const ElementPointDetails = () => {
  const { selectedTab } = useSnapshot(tabs);
  const cPressed = useKey('c');

  cPressed && setSelectedTab('Fittings');

  return (
    <>
      <Tabs value={selectedTab} onChange={(_, nextValue) => setSelectedTab(nextValue)}>
        <Tab label="Info" value="Info" />
        <Tab label="Elements" value="Elements" />
        <Tab label="Fittings [C]" value="Fittings" />
      </Tabs>
      <Divider />
      {selectedTab === 'Info' && (
        <Stack padding={2} gap={1}>
          <MarkerSphere />
          <PipeConnectors />
        </Stack>
      )}
      {selectedTab === 'Elements' && (
        <>
          <CustomScrollContainer gap={2} flexGrow={1} p={2}>
            <ElementSelect />
          </CustomScrollContainer>
          <HouseLeadDetails />
        </>
      )}
      {selectedTab === 'Fittings' && (
        <CustomScrollContainer gap={2} flexGrow={1}>
          <PipeFittings />
        </CustomScrollContainer>
      )}
    </>
  );
};
