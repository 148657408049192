import { MergeA, Split } from '@deepup/icons';
import {
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { Icon } from '@core/types';

export interface SelectableItem {
  canBeMerged: boolean;
  canBeSelected?: boolean;
  canBeSplit?: boolean;
  color?: string;
  Icon?: Icon;
  id: string;
  isLoading: boolean;
  isSelected: boolean;
  subtitle?: string;
  title: string;
}

const sortByTitle = (a: SelectableItem, b: SelectableItem): number => (a.title < b.title ? -1 : 1);

const SecondaryAction = ({
  id,
  canBeMerged,
  canBeSplit,
  isLoading,
  onMergeItems,
  onSplitItem,
}: {
  id: string;
  canBeMerged?: boolean;
  canBeSplit?: boolean;
  isLoading: boolean;
  onMergeItems?: (itemId: string) => () => void;
  onSplitItem?: (itemId: string) => () => void;
}) => {
  const secondaryAction = (canBeMerged ? onMergeItems : canBeSplit ? onSplitItem : undefined)?.(id);

  if (!secondaryAction) return null;

  const secondaryActionText = canBeMerged
    ? 'Merge with selected'
    : canBeSplit
    ? 'Split at selected point'
    : undefined;
  const secondaryActionIcon = isLoading ? (
    <CircularProgress color="inherit" size={18} />
  ) : canBeMerged ? (
    <MergeA fill="currentColor" />
  ) : canBeSplit ? (
    <Split fill="currentColor" />
  ) : undefined;

  return (
    <IconButton
      size="small"
      edge="end"
      onClick={(e) => {
        e.stopPropagation();
        secondaryAction();
      }}
      title={secondaryActionText}
      disabled={isLoading}
    >
      {secondaryActionIcon}
    </IconButton>
  );
};

export const Selectable3DObjectList = ({
  onMergeItems,
  onSplitItem,
  items,
  selectItem,
}: {
  items: SelectableItem[];
  onMergeItems?: (itemId: string) => () => void;
  onSplitItem?: (itemId: string) => () => void;
  selectItem: (itemId: string) => MouseEventHandler;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' }), 100);
  }, [ref.current]);

  if (!items.length) return null;

  return (
    <Card elevation={8}>
      <List disablePadding>
        {items
          .sort(sortByTitle)
          .map(
            ({
              isSelected,
              id,
              canBeSelected,
              Icon,
              title,
              canBeSplit,
              subtitle,
              color,
              canBeMerged,
              isLoading,
            }) => (
              <ListItemButton
                ref={isSelected ? ref : undefined}
                key={id}
                dense
                disabled={canBeSelected === false}
                selected={isSelected}
                onClick={selectItem(id)}
                divider
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon fill={color} fontSize={24} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true }}
                  primary={title}
                  secondary={subtitle}
                  title={title + (subtitle ? ' – ' + subtitle : '')}
                />
                <SecondaryAction
                  id={id}
                  canBeMerged={canBeMerged}
                  canBeSplit={canBeSplit}
                  isLoading={isLoading}
                  onMergeItems={onMergeItems}
                  onSplitItem={onSplitItem}
                />
              </ListItemButton>
            ),
          )}
      </List>
    </Card>
  );
};
