import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getItem, setItem } from 'localforage';
import { useEffect, useState } from 'react';

/**
 * Workaround for caching blobs for google's signed urls.
 * TODO [https://gitlab.com/deepup-gmbh/team-backoffice/-/issues/19]: Remove as soon as signed cookie mechanism exists
 */
export const useCachedUrl = (
  url?: string,
  requestConfig?: () => Promise<AxiosRequestConfig>,
): string | undefined => {
  const [cachedUrl, setCachedUrl] = useState<string>();

  useEffect(() => {
    if (!url) return;

    const simplifiedPath = url.split('?')[0];
    let urlToRevoke: string | null = null;

    getItem(simplifiedPath)
      .then(async (arrayBuffer) => {
        if (arrayBuffer && arrayBuffer instanceof ArrayBuffer) return arrayBuffer;
        const config: AxiosRequestConfig = {
          responseType: 'arraybuffer',
          ...(requestConfig ? await requestConfig() : {}),
        };
        const response: AxiosResponse<ArrayBuffer> = await axios.get(url, config);
        await setItem(simplifiedPath, response.data);
        return response.data;
      })
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer]);
        urlToRevoke = URL.createObjectURL(blob);
        setCachedUrl(urlToRevoke);
      })
      .catch(console.error);
    return () => {
      if (!urlToRevoke) return;
      URL.revokeObjectURL(urlToRevoke);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, requestConfig]);

  return cachedUrl;
};
