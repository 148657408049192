import { TimeEvent, useTimeEvents } from '@core/analytics';
import { useTools } from '@core/components';
import { setProj4Projection, useKeyCombination, useSelectAndFocusPointsById } from '@core/logic';
import { elementActions, elementThunks, elementTypeThunks } from '@elements/redux';
import { pipeConnectorThunks } from '@elements/redux/connectors.slice';
import { markerSphereThunks } from '@elements/redux/markers.slice';
import { pipeFittingThunks } from '@elements/redux/pipe-fitting.slice';
import { pointElementActions, pointElementThunks } from '@elements/redux/point-element.slice';
import { Divider, Drawer, LinearProgress, Stack, Typography } from '@mui/material';
import { projectsHistoryActions } from '@menu/redux/projectsHistory';
import { EditModes, editModeState } from '@projects/edit-modes';
import { projectThunks } from '@projects/redux';
import { ScanDetails } from '@scans/components';
import { tagThunks } from '@tags/redux';
import { trenchActions, trenchThunks } from '@trenches/redux';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { PhotoPreview } from '@photos/components/PhotosInViewport/PhotoPreview';
import { EditMode } from '@projects/edit-modes/types';
import { useSnapshot } from 'valtio';
import { MapLayersBox } from './LayerToggles';
import { ProjectMenu } from './menu';
import { ProjectCanvas } from './ProjectCanvas';
import { ProjectBar } from './ProjectBar';
import { useScanStats } from './hooks';
import { ElementPointDetails, TrenchPointDetails } from './PointDetails';

/**
 * This is its own component so React doesn't need to rerender ProjectDetail everytime something changes here (performance!)
 */
const DebugComponent = () => {
  useSelectAndFocusPointsById();
  return null;
};

export const ProjectDetail = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const project = useSelector(viewer3dSelectors.selectedProject);
  const isScanSelected = !!useSelector(viewer3dSelectors.selectedScanId);
  const { mode: selectedEditMode } = useSnapshot(editModeState);
  const scanDetailsOpen = isScanSelected && selectedEditMode === EditMode.Scan;
  const isPointSelected = !!useSelector(viewer3dSelectors.selectedPointId);
  const elementPointDetailsOpen = isPointSelected && selectedEditMode === EditMode.Element;
  const trenchPointDetailsOpen = isPointSelected && selectedEditMode === EditMode.Trench;

  const { loadedVisiblePercent, loading } = useScanStats();
  const theme = useTheme();
  const leftDrawerWidth = theme.spacing(52);
  const rightDrawerWidth = theme.spacing(46);

  useTools();

  useEffect(() => {
    if (!projectId) return;
    dispatch(viewer3dActions.setSelectedProjectId(projectId));
    dispatch(viewer3dActions.clearSelection());

    dispatch(
      projectThunks.getOne({
        path: ['projects', projectId],
        params: { projection: 'scanCount' },
      }),
    );
    dispatch(trenchActions.removeAll());
    dispatch(
      trenchThunks.getMany({
        path: ['projects', projectId, 'trenches'],
      }),
    );
    dispatch(elementActions.removeAll());
    dispatch(
      elementThunks.getMany({
        path: ['projects', projectId, 'splineElements'],
      }),
    );
    dispatch(pointElementActions.removeAll());
    dispatch(
      pointElementThunks.getMany({
        path: ['projects', projectId, 'pointElements'],
      }),
    );
    dispatch(
      pipeFittingThunks.getMany({
        path: ['projects', projectId, 'splineElementConnections'],
      }),
    );
    dispatch(
      pipeConnectorThunks.getMany({
        path: ['projects', projectId, 'connectors'],
      }),
    );
    dispatch(
      markerSphereThunks.getMany({
        path: ['projects', projectId, 'markers'],
      }),
    );
    dispatch(
      elementTypeThunks.getMany({
        path: ['splineElementTypes'],
        params: { page: 0, size: 1000 },
      }),
    );
    dispatch(tagThunks.getMany({ path: ['tags'], params: { size: 1000 } }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!project?.epsgCode || !project?.proj4Projection) {
      return;
    }
    project && setProj4Projection(project.epsgCode, project.proj4Projection);
  }, [project?.epsgCode, project?.proj4Projection]);

  useEffect(() => {
    if (project) {
      document.title = project.name;
      dispatch(projectsHistoryActions.addToRecent({ id: project.id, title: project.name }));
    }
  }, [project]);

  /**  
    Last parameter is used to inject additional data to the event payload. 
    We use router param instead of project object since it is not available on router navigation
  */
  useTimeEvents([TimeEvent.TimeSpentProjectDetail], [project?.id], { projectId: projectId! });

  const toggleUI = useKeyCombination('Meta', '.');

  if (!project) return null;

  const isLoadingFinished = !loading && (loadedVisiblePercent <= 0 || loadedVisiblePercent >= 100);

  const topAppBarHeight = theme.spacing(6);

  return (
    <>
      <DebugComponent />
      <Stack>
        <LinearProgress
          variant="determinate"
          value={loadedVisiblePercent}
          sx={(theme) => ({
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: theme.zIndex.drawer + 1,
            opacity: isLoadingFinished ? 0 : 1,
            transition: theme.transitions.create('opacity'),
          })}
        />
        <Drawer
          sx={{ display: toggleUI ? 'none' : 'flex' }}
          variant="permanent"
          anchor="left"
          PaperProps={{
            elevation: 2,
            sx: {
              width: leftDrawerWidth,
              pt: topAppBarHeight, // to prevent the drawer from being occluded by the AppBar
            },
          }}
        >
          <ProjectMenu key={project.id} />
        </Drawer>
        <Drawer
          role="complementary"
          aria-label="details"
          variant="persistent"
          anchor="right"
          open={scanDetailsOpen || elementPointDetailsOpen || trenchPointDetailsOpen}
          PaperProps={{
            elevation: 2,
            sx: {
              width: rightDrawerWidth,
              pt: topAppBarHeight, // to prevent the drawer from being occluded by the AppBar
              overflow: 'hidden',
            },
          }}
        >
          <Typography variant="body1" fontWeight="bold" padding={2}>
            {scanDetailsOpen
              ? 'Selected Scan'
              : elementPointDetailsOpen
              ? 'Selected Element Point'
              : trenchPointDetailsOpen
              ? 'Selected Trench Point'
              : null}
          </Typography>
          <Divider />
          {scanDetailsOpen ? (
            <ScanDetails />
          ) : elementPointDetailsOpen ? (
            <ElementPointDetails />
          ) : trenchPointDetailsOpen ? (
            <TrenchPointDetails />
          ) : null}
        </Drawer>

        <Stack
          sx={{
            display: toggleUI ? 'none' : 'flex',
            overflow: 'hidden',
            ml: leftDrawerWidth,
            pr:
              scanDetailsOpen || elementPointDetailsOpen || trenchPointDetailsOpen
                ? rightDrawerWidth
                : 0,
            zIndex: theme.zIndex.drawer - 1,
          }}
        >
          <ProjectBar />
        </Stack>

        <Stack
          direction="row"
          justifyContent={'start'}
          mt={2}
          ml={leftDrawerWidth}
          pl={2}
          maxWidth={theme.spacing(32)}
          zIndex={theme.zIndex.drawer}
        >
          <MapLayersBox />
        </Stack>

        <PhotoPreview />

        <ProjectCanvas />

        <EditModes />
      </Stack>
    </>
  );
};
