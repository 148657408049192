import type { Entity } from '@core/types';
import { PointElementSubtype } from '@elements/types';

export interface Project extends Entity {
  name: string;
  scanCounts: {
    total: number;
    qaToClarify: number;
    qaToLabel: number;
    qaReady: number;
    qaDone: number;
    qaToRework: number;
  };
  utmZone: number;
  epsgCode: string;
  proj4Projection: string;
  hasPipeDirections: boolean;
  exportTarget: ExportTarget | null;
  metadata: ProjectMetadata[];
}

export enum ExportTarget {
  Telekom = 'TELEKOM',
}

export type ProjectMetadata = {
  id: string;
  name: string;
  subtype: PointElementSubtype;
  smaNumber: string;
};
